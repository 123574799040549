<div class="banner banner-default">
  <div class="title">
    <h1>
      <!-- <span class="font-bilo-thin">DIS</span> -->
      <span class="font-bilo-bold">DISCLAIMER</span>
    </h1>
  </div>
</div>

<div class="content content-default">
  <p>
    Op het gebruik van deze website (richardjansen.net) zijn onderstaande
    gebruiksvoorwaarden van toepassing. Door gebruik te maken van deze website,
    wordt u geacht kennis te hebben genomen van de gebruiksvoorwaarden en deze
    te hebben aanvaard.
  </p>

  <h2>GEBRUIK VAN INFORMATIE</h2>
  <p>
    Richard Jansen streeft ernaar op deze website altijd juiste en actuele
    informatie aan te bieden. Hoewel deze informatie met de grootst mogelijke
    zorgvuldigheid is samengesteld, staat Richard Jansen niet in voor de
    volledigheid, juistheid of actualiteit van de informatie. De juridische
    informatie op de website is van algemene aard en kan niet worden beschouwd
    als een vervanging van juridisch advies.
  </p>
  <p>
    Aan de informatie kunnen geen rechten worden ontleend. Richard Jansen
    aanvaardt geen aansprakelijkheid voor schade die voortvloeit uit het gebruik
    van de informatie of de website en evenmin voor het niet goed functioneren
    van de website. Op basis van het verzenden en ontvangen van informatie via
    de website of via e-mail kan niet zonder meer een relatie tussen Richard
    Jansen en de gebruiker van de website ontstaan.
  </p>
  <h2>E-MAIL</h2>
  <p>
    Richard Jansen garandeert niet dat e-mails die aan richardjansen71@gmail.com
    worden verzonden (tijdig) worden ontvangen of verwerkt, omdat tijdige
    ontvangst van e-mails niet kan worden gegarandeerd. Ook de veiligheid van
    het e-mailverkeer kan niet volledig worden gegarandeerd door de hieraan
    verbonden veiligheidsrisico's. Door zonder encryptie of
    wachtwoordbeveiliging per e-mail met Richard Jansen te corresponderen,
    accepteert u dit risico.
  </p>
  <h2>HYPERLINKS</h2>

  <p>
    Deze website kan hyperlinks bevatten naar websites van derden. Richard
    Jansen heeft geen invloed op websites van derden en is niet verantwoordelijk
    voor de beschikbaarheid of inhoud daarvan. Richard Jansen aanvaardt dan ook
    geen aansprakelijkheid voor schade die voortvloeit uit het gebruik van
    websites van derden.
  </p>

  <h2>INTELECTUELE EIGENDOMSRECHTEN</h2>
  <p>
    Alle publicaties en uitingen van Richard Jansen zijn beschermd door
    auteursrecht en andere intellectuele eigendomsrechten. Behalve voor
    persoonlijk en niet-commercieel gebruik, mag niets uit deze publicaties en
    uitingen op welke manier dan ook verveelvoudigd, gekopieerd of op een andere
    manier openbaar worden gemaakt, zonder dat Richard Jansen daar vooraf
    schriftelijke toestemming voor heeft gegeven.
  </p>
</div>
