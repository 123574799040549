<div #transitions class="impressions" id="impressions">
  <div class="overlay left"></div>
  <div class="overlay right"></div>
  <ul #carrousel id="carrousel" class="carrousel">
    <li
      class="carrousel-item"
      *ngFor="let impression of impressions.slice(); let i = index"
      [ngClass]="i == curPage ? 'active' : 'inactive'"
    >
      <div class="image-container">
        <img
          [attr.id]="'impression-' + i"
          [ngClass]="i == curPage ? 'active' : 'inactive'"
          class="impression {{ i }} {{ curPage }}"
          src="assets/impressions/{{ impression.source }}"
        />
      </div>
      <div class="description">
        <div class="title">{{ impression.title }}</div>
        <div class="canvas-size">{{ impression.size }}</div>
        <div class="media">{{ impression.media }}</div>
        <div class="price">
          <a
            target="_blank"
            href="mailto:richardjansen71@gmail.com?subject=richardjansen.net price request: {{
              impression.title
            }}"
            *ngIf="impression.price == '(Price on request)'"
            >{{ impression.price }}</a
          >
          <span *ngIf="impression.price != '(Price on request)'">{{
            impression.price
          }}</span>
        </div>
      </div>
    </li>
  </ul>
  <ul #bullets class="bullets">
    <li *ngFor="let impression of impressions.slice(); let i = index">
      <div class="circle white">&nbsp;</div>
      <div
        [attr.id]="'bullet-' + i"
        [ngStyle]="{
          'background-color': i === curPage ? impression.colorSecondary : ''
        }"
        [ngClass]="i == curPage ? 'active' : 'inactive'"
        class="circle dynamic"
        (click)="navigate(i)"
      >
        &nbsp;
      </div>
    </li>
  </ul>
  <div class="pagination">
    <input
      class="page-navigation previous"
      type="image"
      src="/assets/icon-pagination.png"
      [disabled]="curPage == 0"
      [ngClass]="{ disabled: curPage == 0 }"
      (click)="navigate(curPage - 1)"
    />

    <span> {{ curPage + 1 }} of {{ impressions.length }} </span>

    <input
      class="page-navigation"
      type="image"
      src="/assets/icon-pagination.png"
      [disabled]="curPage >= impressions.length - 1"
      [ngClass]="{ disabled: curPage >= impressions.length - 1 }"
      (click)="navigate(curPage + 1)"
    />
  </div>
</div>
