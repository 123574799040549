<div class="banner"></div>
<div class="filter">
  <ul class="filter-switch">
    <li
      [ngClass]="{ active: activeFilter == 'sale' }"
      (click)="filterGallery('sale')"
    >
      TE KOOP
    </li>
    <li
      [ngClass]="{ active: activeFilter == 'sold' }"
      (click)="filterGallery('sold')"
    >
      VERKOCHT
    </li>
  </ul>
</div>
<div class="gallery">
  <ul>
    <li
      *ngFor="
        let painting of artworks.slice().reverse()
          | slice : curPage * pageSize - pageSize : curPage * pageSize;
        let i = index
      "
      style="background-image: url('assets/paintings/{{ painting.thumb }}')"
    >
      <div
        class="action"
        title="click to enlarge"
        (click)="enlarge(i)"
        [attr.data-index]="i"
      >
        <div class="plus">+</div>
      </div>
      <div class="description">
        <div class="title">{{ painting.title }}</div>
        <div class="canvas-size">{{ painting.size }}</div>
        <div class="media">{{ painting.media }}</div>
        <div class="price">
          <a
            target="_blank"
            href="mailto:richardjansen71@gmail.com?subject=richardjansen.net price request: {{
              painting.title
            }}"
            *ngIf="painting.price == '(Price on request)'"
            >{{ painting.price }}</a
          >
          <span *ngIf="painting.price != '(Price on request)'">{{
            painting.price
          }}</span>
        </div>
      </div>
    </li>
  </ul>
</div>

<div class="pagination">
  <input
    class="page-navigation previous"
    type="image"
    src="/assets/icon-pagination.png"
    [disabled]="curPage == 1"
    [ngClass]="{ disabled: curPage == 1 }"
    (click)="curPage = curPage - 1"
  />
  <span> Page {{ curPage }} of {{ numberOfPages() }} </span>

  <input
    class="page-navigation"
    type="image"
    src="/assets/icon-pagination.png"
    [disabled]="curPage >= artworks.length / pageSize"
    [ngClass]="{ disabled: curPage >= artworks.length / pageSize }"
    (click)="curPage = curPage + 1"
  />
</div>

<div class="enlarge" [ngClass]="{ active: showOverlay }" (click)="close()">
  <!-- <div class="enlarge" *ngIf=selectedItem [ngClass]="{selectedItem ? 'active'}"> -->
  <div class="close" (click)="close()">X</div>
  <div class="container" *ngIf="selectedItem">
    <div class="frame">
      <div
        class="next"
        title="Click for next painting"
        (click)="next($event)"
      ></div>
      <div
        class="prev"
        title="Click for previous painting"
        (click)="previous($event)"
      ></div>
      <div class="meta">
        <div class="title">{{ selectedItem.title }}</div>
        <div class="canvas-size">{{ selectedItem.size }}</div>
        <div class="media">{{ selectedItem.media }}</div>
        <!-- <div class="price">{{ selectedItem.price }}</div> -->
        <div class="price">
          <a
            target="_blank"
            href="mailto:richardjansen71@gmail.com?subject=richardjansen.net price request: {{
              selectedItem.title
            }}"
            *ngIf="selectedItem.price == '(Price on request)'"
            >{{ selectedItem.price }}</a
          >
          <span *ngIf="selectedItem.price != '(Price on request)'">{{
            selectedItem.price
          }}</span>
        </div>
      </div>

      <img class="painting" src="assets/paintings/{{ selectedItem.source }}" />
    </div>
  </div>
</div>
