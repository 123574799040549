import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('hamburger', { read: ElementRef }) public hamburger: ElementRef<any>;
  @ViewChild('nav', { read: ElementRef }) public nav: ElementRef<any>;
  @ViewChild('footer', { read: ElementRef }) public footer: ElementRef<any>;

  title = 'jansen-art';
  date = new Date().getFullYear();
  cookieContent: any = document.cookie;

  menuIsActive: boolean = false;

  constructor() { }

  ngOnInit(): void {}

  setCookieConsent(consent: boolean) {
    const cookieExpire = new Date();
    cookieExpire.setMonth(cookieExpire.getMonth() + 12);
    document.cookie = 'cookieConsent=' + consent + '; expires=' + cookieExpire.toUTCString() + '; path=/';
      this.cookieContent = document.cookie;
  }
  
  toggleMenu() {
    if(this.menuIsActive) {
      this.nav.nativeElement.classList.remove('open');
      this.footer.nativeElement.classList.remove('open');
      this.hamburger.nativeElement.classList.remove('close');
      this.menuIsActive = false;
    }
    else {
      this.nav.nativeElement.classList.add('open');
      this.footer.nativeElement.classList.add('open');
      this.hamburger.nativeElement.classList.add('close');
      this.menuIsActive = true;
    }
  }

  scrollToTop() {
   window.scroll({ 
           top: 0, 
           left: 0, 
           behavior: 'smooth' 
    });
  }
}
