<div class="banner banner-default">
  <div class="title">
    <h1>
      <span class="font-bilo-thin">COOKIE</span>
      <span class="font-bilo-bold">STATEMENT</span>
    </h1>
  </div>
</div>

<div class="content content-default">
  <p>
    richardjansen.net maakt gebruik van cookies. Een cookie is een eenvoudig
    klein bestandje dat met pagina's van deze website wordt meegestuurd en door
    je browser op je harde schijf van je computer, mobiele telefoon, smart watch
    of tablet wordt opgeslagen. De daarin opgeslagen informatie kan bij een
    volgend bezoek weer naar onze servers teruggestuurd worden.
  </p>
  <p>
    Het gebruik van cookies is van groot belang voor het goed laten draaien van
    deze website, maar ook cookies waarvan je niet direct het effect ziet zijn
    zeer belangrijk. Dankzij de (anonieme) input van bezoekers kunnen we het
    gebruik van de website verbeteren en deze gebruiksvriendelijker maken.
  </p>
  <h2>TOESTEMMING VOOR HET GEBRUIK VAN COOKIES</h2>
  <p>
    Voor het gebruik van bepaalde cookies is jouw toestemming vereist. Dit doen
    wij door middel van een zogenaamde cookiebanner.
  </p>
  <h2>TYPE COOKIES EN HUN DOELSTELLING</h2>
  <p>Wij gebruiken de volgende type cookies:</p>
  <p>
    <strong>Functionele cookies:</strong> <br />Hiermee kunnen we de website
    beter laten functioneren en is die gebruiksvriendelijker voor de bezoeker.
    Bijvoorbeeld: we slaan je inloggegevens op of wat je in je winkelmandje hebt
    gestopt. 
  </p>
  <p>
    <strong>Geanonimiseerde analytische cookies:</strong><br />
    Deze zorgen ervoor dat iedere keer wanneer je een website bezoekt er een
    anonieme cookie wordt gegenereerd. Deze cookies weten of je de site al
    eerder bezocht hebt of niet. Alleen bij het eerste bezoek, wordt er een
    cookie aangemaakt, bij volgende bezoeken wordt er gebruikgemaakt van de
    reeds bestaande cookie. Deze cookie dient enkel voor statistische
    doeleinden. Zo kunnen daarmee de volgende data verzameld worden:
  </p>
  <ul>
    <li>het aantal unieke bezoekers</li>
    <li>hoe vaak gebruikers de site bezoeken</li>
    <li>welke pagina's gebruikers bekijken</li>
    <li>hoelang gebruikers een bepaalde pagina bekijken</li>
    <li>bij welke pagina bezoekers de site verlaten</li>
  </ul>
  <!--
  <p>
    <strong>Analytische cookies:</strong><br />
    Deze zorgen ervoor dat iedere keer wanneer je een website bezoekt er een
    cookie wordt gegenereerd. Deze cookies weten of je de site al eerder bezocht
    hebt of niet. Alleen bij het eerste bezoek, wordt er een cookie aangemaakt,
    bij volgende bezoeken wordt er gebruikgemaakt van de reeds bestaande cookie.
    Deze cookie dient enkel voor statistische doeleinden. Zo kunnen daarmee de
    volgende data verzameld worden, zoals:
  </p>
  <ul>
    <li>welke pagina's je hebt bekeken</li>
    <li>hoelang je op een bepaalde pagina bent gebleven</li>
    <li>bij welke pagina je de site hebt verlaten</li>
  </ul>

  <p>
    <strong>Social media gerelateerde cookies:</strong><br />
    Hiermee registreren social media zoals Facebook en LinkedIn welke artikels
    en pagina's je deelt via hun sociale media sharing buttons. Ze kunnen ook
    tracking cookies bevatten die je surfgedrag op het web volgen.
  </p>
  <h2>JE RECHTEN MET BETREKKING TOT JE GEGEVENS</h2>
  <p>
    Je hebt het recht op inzage, rectificatie, beperking en verwijdering van
    persoonsgegevens. Daarnaast heb je recht van bezwaar tegen verwerking van
    persoonsgegevens en recht op gegevensoverdraagbaarheid. Je kunt deze rechten
    uitoefenen door ons een mail te sturen via
    <a target="_blank" href="mailto:richardjansen71@gmail.com"
      >richardjansen71@gmail.com</a
    >. Om misbruik te voorkomen kunnen wij je daarbij vragen om je adequaat te
    identificeren. Wanneer het gaat om inzage in persoonsgegevens gekoppeld aan
    een cookie, vragen we je een kopie van het cookie in kwestie mee te sturen.
    Je kunt deze terugvinden in de instellingen van je browser.
  </p>

  -->
  <h2>COOKIES BLOKKEREN EN VERWIJDEREN</h2>
  <p>
    Je kunt cookies te allen tijde eenvoudig zelf blokkeren en verwijderen via
    je internetbrowser. Ook kun je je internetbrowser zodanig instellen dat je
    een bericht ontvangt als er een cookie wordt geplaatst. Je kunt ook aangeven
    dat bepaalde cookies niet geplaatst mogen worden. Bekijk voor deze
    mogelijkheid de helpfunctie van je browser. Als je de cookies in je browser
    verwijdert, kan dat gevolgen hebben voor het prettige gebruik van deze
    website. Sommige tracking cookies worden geplaatst door derden die onder
    meer via onze website advertenties aan je vertonen. Deze cookies kan je
    centraal verwijderen via
    <a target="_blank" href="youronlinechoices.com">youronlinechoices.com</a>
  </p>
  <p>
    Wees je er wel van bewust dat als je geen cookies wilt, wij niet meer kunnen
    garanderen dat onze Website helemaal goed werkt. Het kan zijn dat enkele
    functies van de site verloren gaan of zelfs dat je de website helemaal niet
    meer kunt bezoeken. Daarnaast betekent het weigeren van cookies ook niet dat
    je helemaal geen advertenties meer te zien krijgt. De advertenties zijn dan
    alleen niet meer toegesneden op jouw interesses en kun daardoor vaker worden
    herhaald. Hoe je je instellingen kunt aanpassen, verschilt per browser.
    Raadpleeg indien nodig de helpfunctie van jouw browser, of klik op
    &eacute;&eacute;n van de onderstaande links om direct naar de handleiding
    van je browser te gaan.
  </p>
  <ul>
    <li>
      <a
        target="_blank"
        href="https://support.mozilla.org/nl/kb/cookies-verwijderen-gegevens-wissen-websites-opgeslagen"
        >Firefox</a
      >
    </li>
    <li>
      <a
        target="_blank"
        href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform=Desktop&hl=nl"
        >Google Chrome</a
      >
    </li>
    <li>
      <a target="_blank" href="https://support.microsoft.com/nl-nl/kb/278835"
        >Internet Explorer</a
      >
    </li>
    <li>
      <a target="_blank" href="https://support.apple.com/nl-nl/HT201265"
        >Safari op smart phone</a
      >
    </li>
    <li>
      <a
        target="_blank"
        href="https://support.apple.com/nl-be/guide/safari/sfri11471/mac"
        >Safari op Mac</a
      >
    </li>
  </ul>

  <h2>NIEUWE ONTWIKKELINGEN EN ONVOORZIENE COOKIES</h2>
  <p>
    De teksten van onze website kunnen op ieder moment worden aangepast door
    voortdurende ontwikkelingen. Dit geldt ook voor onze cookieverklaring. Neem
    deze verklaring daarom regelmatig door om op de hoogte te blijven van
    eventuele wijzigingen.
  </p>
  <p>
    In blogartikelen kan gebruik worden gemaakt van content die op andere sites
    wordt gehost en op richardjansen.net wordt ontsloten door middel van
    bepaalde codes (embedded content). Denk hierbij aan bijvoorbeeld YouTube
    video's. Deze codes maken vaak gebruik van cookies. Wij hebben echter geen
    controle op wat deze derde partijen met hun cookies doen.Het kan ook
    voorkomen dat via onze websites cookies worden geplaatst door anderen,
    waarvan wijzelf niet altijd op de hoogte zijn. Kom je op onze website
    onvoorziene cookies tegen die je niet kunt terugvinden in ons overzicht?
    Laat het ons weten via
    <a target="_blank" href="mailto:richardjansen71@gmail.com"
      >richardjansen71@gmail.com</a
    >. Je kan ook rechtstreeks contact opnemen met de derde partij en vraag
    welke cookies ze plaatsten, wat de reden daarvoor is, wat de levensduur van
    de cookie is en op welke manier ze je privacy gewaarborgd hebben.
  </p>
  <h2>SLOTOPMERKINGEN</h2>
  <p>
    Wij zullen deze verklaringen af en toe aan moeten passen, bijvoorbeeld
    wanneer we onze website aanpassen of de regels rondom cookies wijzigen. Je
    kunt deze webpagina raadplegen voor de laatste versie. Mocht je nog vragen
    en/of opmerkingen hebben neem dan contact op met
    <a target="_blank" href="mailto:richardjansen71@gmail.com"
      >richardjansen71@gmail.com</a
    >.
  </p>
</div>
