<div class="banner banner-default">
  <div class="title title--pic-margin">
    <h1>
      <span class="font-bilo-thin">OVER</span>
      <span class="font-bilo-bold">RICHARD</span>
    </h1>
  </div>
</div>

<div class="content content-default">
  <img
    class="pic pic--left pic--margin-top"
    src="/assets/profile-picture.jpg"
    width="230"
    height="230"
    alt="Picture Richard Jansen"
  />
  <p>
    De ziel van het moment vangen als een vorm van live muziek registratie.
    Schilderen vanuit het moment, zonder doel, zonder plan. Voortdurend alert op
    het onverwachte. Op zoek naar de balans tussen rauwe energie van de
    ongecontroleerd geplaatste kwast en rust van composite. Zo werk ik het
    liefst.
  </p>
  <p>
    Het is niet altijd even makkelijk om in deze flow te geraken. De gedachte
    meldt zich namelijk voortdurend tijdens het proces. Waar de onderbuik
    beslissingen maakt in een split second, gaat het hoofd rationaliseren. Dit
    resulteert veelal in een poldermodel schilderij. Een allegaartje
    weloverwogen keuzes, die de toevalligheid en de schoonheid van de rauwe
    kwast te niet doen. Een schilderij verandert van puur en onbevangen naar
    gecontroleerd beheerst. Hoe langer het duurt om een schilderij te maken, hoe
    groter de kans dat ratio zich er mee gaat bemoeien...
  </p>

  <h2>CURRICULUM VITAE</h2>

  <ul class="curriculum">
    <li>
      <span class="curriculum-date">'71</span>
      <span class="curriculum-place">Musselkanaal <em>(Groningen)</em></span>
      <span class="curriculum-description">Geboren</span>
    </li>
    <li>
      <span class="curriculum-date">'89-'92</span>
      <span class="curriculum-place">Zwolle <em>(Overijssel)</em></span>
      <span class="curriculum-description">Grafisch Lyceum</span>
    </li>
    <li>
      <span class="curriculum-date">'92-'97</span>
      <span class="curriculum-place">Maastricht <em>(Limburg)</em></span>
      <span class="curriculum-description"
        >Academie Beeldende Kunsten Maastricht (ABK) - Grafische
        Vormgeving</span
      >
    </li>
    <li>
      <span class="curriculum-date">'92</span>
      <span class="curriculum-place">Venetië <em>(Itali&euml;)</em></span>
      <span class="curriculum-description"
        >Gevraagd om de ABK te vertegenwoordigen tijdens de biënnale van Venetië
      </span>
    </li>
    <li>
      <span class="curriculum-date">'96</span>
      <span class="curriculum-place">Arnhem <em>(Gelderland)</em></span>
      <span class="curriculum-description"
        >Bronzen medaille ontwerpwedstrijd 100 jaar Olympische Spelen NOC*NSF
      </span>
    </li>
    <li>
      <span class="curriculum-date">'98-'23</span>
      <span class="curriculum-place">Nederland</span>
      <span class="curriculum-description"
        >Designer voor o.a. Philips, Sanoma, Rijksoverheid, Engie, Amsterdam
        Museums, Rabobank
      </span>
    </li>
    <li>
      <span class="curriculum-date">'02</span>
      <span class="curriculum-place">Zeist <em>(Utrecht)</em> </span>
      <span class="curriculum-description"
        ><a
          target="_blank"
          href="https://www.computable.nl/artikel/nieuws/ict-branche/158272/250449/postbus51-wint-usability-award-2002.html
"
          >Usability Award</a
        ></span
      >
    </li>
    <li>
      <span class="curriculum-date">'05</span>
      <span class="curriculum-place">Amsterdam <em>(Noord-Holland)</em> </span>
      <span class="curriculum-description">Sanoma Design Award </span>
    </li>
    <li>
      <span class="curriculum-date">'08</span>
      <span class="curriculum-place">Zwolle <em>(Overijssel)</em> </span>
      <span class="curriculum-description"
        >Oprichting kunstgenoodschap BlaBla</span
      >
    </li>
    <li>
      <span class="curriculum-date">'08</span>
      <span class="curriculum-place">Amsterdam <em>(Noord-Holland)</em> </span>
      <span class="curriculum-description"
        >BlaBla expositie @ Openbare Bibliotheek Amsterdam (OBA)</span
      >
    </li>
    <li>
      <span class="curriculum-date">'10</span>
      <span class="curriculum-place"
        >Willemstad <em>(Cura&ccedil;ao)</em>
      </span>
      <span class="curriculum-description">Solo expositie</span>
    </li>
    <li>
      <span class="curriculum-date">'12</span>
      <span class="curriculum-place">Zoetermeer <em>(Zuid-Holland)</em> </span>
      <span class="curriculum-description">Deelname kunstroute</span>
    </li>
    <li>
      <span class="curriculum-date">'13</span>
      <span class="curriculum-place">Amsterdam <em>(Noord-Holland)</em> </span>
      <span class="curriculum-description"
        >Winnaar competitie New Master Artist
      </span>
    </li>
    <li>
      <span class="curriculum-date">'13</span>
      <span class="curriculum-place">Amsterdam <em>(Noord-Holland)</em> </span>
      <span class="curriculum-description"
        >Deelname aan Affordable Artfair Amsterdam
      </span>
    </li>
    <li>
      <span class="curriculum-date">'13</span>
      <span class="curriculum-place">Houston <em>(USA)</em> </span>
      <span class="curriculum-description"
        >Winnaar competitie Aleatoric Art Gallery
      </span>
    </li>
    <li>
      <span class="curriculum-date">'14</span>
      <span class="curriculum-place">Oestgeest <em>(Zuid-Holland)</em> </span>
      <span class="curriculum-description"
        >Expositie in kunstgalerie Grunerie
      </span>
    </li>
    <li>
      <span class="curriculum-date">'17</span>
      <span class="curriculum-place">Stramproy <em>(Limburg)</em> </span>
      <span class="curriculum-description">Deelname kunstroute Mooi Rooj </span>
    </li>
    <li>
      <span class="curriculum-date">'19</span>
      <span class="curriculum-place">Syktyvkar <em>(Rusland)</em> </span>
      <span class="curriculum-description"
        >Solo-expositie National Gallery Syktyvkar in de republiek Komi van de
        Sovjet-unie
      </span>
    </li>
    <li>
      <span class="curriculum-date">'19</span>
      <span class="curriculum-place">Stramproy <em>(Limburg)</em> </span>
      <span class="curriculum-description">Deelname kunstroute Mooi Rooj </span>
    </li>
    <li>
      <span class="curriculum-date">'20</span>
      <span class="curriculum-place">Essen <em>(Duitsland)</em> </span>
      <span class="curriculum-description"
        ><a
          target="_blank"
          href="https://www.red-dot.org/project/intellispace-cognition-49272"
          >Reddot Design Award 2020</a
        ></span
      >
    </li>
    <li>
      <span class="curriculum-date">'20</span>
      <span class="curriculum-place">Berlin <em>(Duitsland)</em> </span>
      <span class="curriculum-description"
        ><a
          target="_blank"
          href="https://ux-design-awards.com/winners/philips-intellispace-cognition"
          >UX Design Award 2020</a
        ></span
      >
    </li>
    <li>
      <span class="curriculum-date">'20</span>
      <span class="curriculum-place">Hannover <em>(Duitsland)</em> </span>
      <span class="curriculum-description"
        ><a
          target="_blank"
          href="https://ifdesign.com/en/winner-ranking/project/philips-intellispace-cognition/273361"
          >IF Design Award 2020</a
        ></span
      >
    </li>
    <li>
      <span class="curriculum-date">'22</span>
      <span class="curriculum-place">Stramproy <em>(Limburg)</em> </span>
      <span class="curriculum-description">Deelname kunstroute Mooi Rooj</span>
    </li>
    <li>
      <span class="curriculum-date">'22-'23</span>
      <span class="curriculum-place">
        Blue Acccountants - Veghel <em>(Noord-Brabant)</em>
      </span>
      <span class="curriculum-description">Vaste solo-expositie</span>
    </li>
    <li>
      <span class="curriculum-date">'23</span>
      <span class="curriculum-place"
        >Kasteel Puth - Voerendaal <em>(Limburg)</em>
      </span>
      <span class="curriculum-description">Pop-up solo-expositie</span>
    </li>
    <li>
      <span class="curriculum-date">'23</span>
      <span class="curriculum-place"
        >Kasteel Puth - Voerendaal <em>(Limburg)</em>
      </span>
      <span class="curriculum-description">Vaste solo-expositie</span>
    </li>
  </ul>
  <hr />
  <div class="goto">Blog items</div>
  <ul class="blogs">
    <li class="blog-item">
      <h2>De naakte waarheid</h2>
      Ik zag het meteen helemaal zitten, tekenen naar de waarneming en dan
      vooral naaktmodel tekenen. Eenieder die er te gewichtig over deed had ik
      nooit vertrouwd. Mij kon je niet wijsmaken dat je volledig stoïcijns kon
      blijven, terwijl er een bloedmooie naakte vrouw voor je uitgestald lag.
      Als pukkelige puber had ik er al vaak over gefantaseerd, maar nu gezeten
      op de Kunstacademie zou het dan eindelijk zover zijn. Opgewekt en
      enigszins gespannen liep ik naar de Academie om vervolgens het tekenlokaal
      binnen te lopen, een ervaring rijker en een illusie armer. Een model kan
      inderdaad net zo goed een man zijn.
      <!-- <hr /> -->
      <!-- <div class="goto" routerLink="/gallery" routerLinkActive="active">
        Lees verder
      </div> -->
    </li>
    <li class="blog-item">
      <h2>DE CREATIEVE BRON</h2>
      Iedereen wordt creatief geboren. De maatschappij zorgt ervoor dat de
      creatieve bron vaak al op jonge leeftijd wordt gedempt. Te beginnen op
      school, waar leraren van je verwachten dat je binnen de lijntjes kleurt.
      Het is een misvatting dat je zou moet kunnen tekenen om te kunnen
      schilderen. Tekenen naar de waarneming is een ambacht en kan worden
      onderwezen. Creativiteit daarentegen is iets wat moet worden gekoesterd en
      aangemoedigd in plaats van onderwezen. Elke opgelegde regel beperkt
      creativiteit en zorgt ervoor dat ongebaande paden niet worden betreden.
      Een misvatting en een groot gemis voor eenieder.
      <!-- <hr /> -->
      <!-- <div class="goto" routerLink="/gallery" routerLinkActive="active">
        Lees verder
      </div> -->
    </li>
  </ul>
  <hr />
  <h2>CONTACT</h2>
  <p>
    Ben je geïnteresseerd in één van mijn schilderijen of heb je een andere
    vraag? <br />Neem dan contact met me op via
    <a href="tel:0031611624272">+31 6 11 624 272</a> of stuur een e-mail naar
    <a target="_blank" href="mailto:richardjansen71@gmail.com"
      >richardjansen71@gmail.com</a
    >
  </p>
</div>
