import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { GalleryComponent } from './gallery/gallery.component';
import { AboutComponent } from './about/about.component';
import { ImpressionsComponent } from './impressions/impressions.component'
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { CookieStatementComponent } from './cookie-statement/cookie-statement.component';
import { PrivacyStatementComponent } from './privacy-statement/privacy-statement.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full' },
  {path: 'over', component: AboutComponent },
  {path: 'gallery', component: GalleryComponent },
  {path: 'home', component: HomeComponent },
  {path: 'impressions', component: ImpressionsComponent},
  {path: 'terms-and-conditions', component: TermsAndConditionsComponent},
  {path: 'cookie-statement', component: CookieStatementComponent},
  {path: 'privacy-statement', component: PrivacyStatementComponent},
  {path: 'disclaimer', component: DisclaimerComponent},
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
