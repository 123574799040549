<div class="root-container">
  <div class="cookie-consent" *ngIf="!cookieContent">
    <span class="cookie-statement">
      Richardjansen.net gebruikt alleen geanonimiseerde cookies om de website
      goed te laten werken en de website te analyseren en verbeteren. Er worden
      geen third party cookies geïnstalleerd en/of marketing cookies. Lees onze
      <a routerLink="/cookie-statement" routerLinkActive="active">
        cookie statement</a
      >
      voor meer informatie.
    </span>
    <span class="button-bar">
      <button (click)="setCookieConsent(false)" class="button button-secondary">
        Afwijzen
      </button>
      <button
        (click)="setCookieConsent(true)"
        onclick="consentGranted()"
        class="button button-primary"
      >
        <!-- <button (click)="setCookieConsent(true)" class="button button-primary"> -->
        Accepteren
      </button>
    </span>
  </div>
  <div #hamburger class="hamburger-menu" (click)="toggleMenu()">
    <div class="hamburger-item one"></div>
    <div class="hamburger-item two"></div>
    <div class="hamburger-item three"></div>
  </div>
  <nav>
    <div #nav class="nav-container-main">
      <ul class="nav-main">
        <li
          class="home"
          routerLink="/home"
          (click)="toggleMenu()"
          routerLinkActive="active"
        >
          Home
        </li>
        <li
          routerLink="/gallery"
          (click)="toggleMenu()"
          routerLinkActive="active"
        >
          Schilderijen
        </li>
        <li
          routerLink="/impressions"
          (click)="toggleMenu()"
          routerLinkActive="active"
        >
          Impressies
        </li>
        <li routerLink="/over" (click)="toggleMenu()" routerLinkActive="active">
          Over Richard Jansen
        </li>
        <!-- <li routerLink="/contact" routerLinkActive="active">Contact</li>
          <li routerLink="/curriculum" routerLinkActive="active">Curriculum</li>
          <li routerLink="/shop" routerLinkActive="active">Shop</li>
          <li routerLink="/uploads" routerLinkActive="active">Upload</li>
          <li routerLink="/orders" routerLinkActive="active">Orders</li> -->
      </ul>
    </div>
    <div #footer class="nav-container-footer">
      <ul class="nav-footer">
        <li
          routerLink="/disclaimer"
          (click)="toggleMenu()"
          routerLinkActive="active"
        >
          Disclaimer
        </li>
        <li
          routerLink="/privacy-statement"
          (click)="toggleMenu()"
          routerLinkActive="active"
        >
          Privacy
        </li>
        <li
          routerLink="/cookie-statement"
          (click)="toggleMenu()"
          routerLinkActive="active"
        >
          Cookies
        </li>
        <li
          routerLink="/terms-and-conditions"
          (click)="toggleMenu()"
          routerLinkActive="active"
        >
          Voorwaarden
        </li>
        <li class="no-decoration">&copy;{{ date }} Richard Jansen</li>
      </ul>
    </div>
  </nav>
  <div class="container-logo">
    <div class="wrapper-logo">
      <div class="logo"><img src="/assets/jansen-logo.png" /></div>
    </div>
  </div>
  <!-- {{ cookieContent }} -->
  <router-outlet (activate)="scrollToTop()"></router-outlet>
</div>
