import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import  art  from  './../../assets/painting.json';
import { IPainting } from './../painting';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  galleryJson: any;
  galleryObject: any;
  curPage: number;
  pageSize: number;
  artworks: IPainting[] = [];
  selectedItem: object;
  itemNumber: number;
  showOverlay: boolean = false;
  browserHeight: number;
  priceOnRequest: IPainting[] = [];
  inPrivateCollection: IPainting[] = [];
  toBeRemasterd: IPainting[] = [];
  remasterd: IPainting[] = [];
  inMuseumCollection: IPainting[] = [];
  onTour: IPainting[] = [];
  activeFilter: any;
  showItems:any;

  constructor(private route: ActivatedRoute) { 
    this.curPage = 1;
    this.pageSize = 12;
  }

  ngOnInit() {


    this.galleryJson = JSON.stringify(art);
    // this.artworks = art.paintings;
    this.artworks = art.filter(object => {
      return object['show'] === 'true';
    });

    // check for URL parameter show
    this.route.queryParams
    .subscribe(params => {
        // console.log(params); // { show: "false" }
        this.showItems = params.show;
        // console.log("params",this.show); // false
        if(this.showItems) {
          let items = (this.showItems).toString();
          // console.log("toString",items); // false
          this.artworks = art.filter(object => {
            return object['show'] == items;
          });
        }
      }
    );


    this.browserHeight = window.innerHeight;
    this.setGallery();
    this.splitGallery();
    // console.log("priceOnRequest", this.priceOnRequest);
    // console.log("this.artworks", this.artworks);
    
  }

  numberOfPages() {
    return Math.ceil(this.artworks.length / this.pageSize);
  }

  enlarge(i: number) {
    this.showOverlay = true;
    this.itemNumber = this.artworks.length - ((this.curPage -1) * this.pageSize) - (i+1);
    this.selectedItem = this.artworks[this.itemNumber];
    
  }

  next(event:Event) {
    event.stopPropagation();
    this.itemNumber = this.itemNumber - 1;
    if (this.itemNumber === -1) {
      this.itemNumber = this.artworks.length - 1;
    }
    this.selectedItem = this.artworks[this.itemNumber];
  }

  previous(event:Event) {
    event.stopPropagation();
    this.itemNumber = this.itemNumber + 1;
    if (this.itemNumber === this.artworks.length) {
      this.itemNumber = 0;
    }
    this.selectedItem = this.artworks[this.itemNumber];
  }

  close() {
    this.showOverlay = false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    this.browserHeight = event.target.innerHeight;
    this.setGallery();
  }

  setGallery() {
    if (this.browserHeight < 800) {
      this.pageSize = 4;
      this.resetGallery();
    }
    else if (this.browserHeight <= 1200) {
      this.pageSize = 8;
      this.resetGallery();
    }
    else {
      this.pageSize = 12;
      this.resetGallery();
    }

  }

  filterGallery(state:any) {
    switch(state) { 
      case 'sale': { 
        this.artworks = this.priceOnRequest;
        this.curPage = 1;
        this.activeFilter = 'sale';
         break; 
      } 
      case 'sold': { 
        this.artworks = this.inPrivateCollection;
        this.curPage = 1;
        this.activeFilter = 'sold';
         break; 
      } 
   } 
  }

  splitGallery() {
    this.priceOnRequest = art.filter(object => {
      return object['price'] == '(Prijs op aanvraag)' && object['show'] == 'true';
    });
    // this.artworks = art.paintings.filter(object => {
    //   return object['price'] == '(Price on request)';
    // });
    this.inPrivateCollection = art.filter(object => {
      return object['price'] == 'Verkocht' && object['show'] == 'true';
    });
    this.toBeRemasterd = art.filter(object => {
      return object['price'] == '(To be remastered)' && object['show'] == 'true';
    });
    this.remasterd = art.filter(object => {
      return object['price'] == '(Remastered)' && object['show'] == 'true';
    });
    this.inMuseumCollection = art.filter(object => {
      return object['price'] == '(In museum collectie)' && object['show'] == 'true';
    });
    this.onTour = art.filter(object => {
      return object['price'] == '(Op tournee)' && object['show'] == 'true';
    });
  }

  resetGallery() {
    // reset current page
    if (this.curPage > this.numberOfPages()) {
      this.curPage = this.numberOfPages();
    }
  }

}
