<div class="banner banner-default">
  <div class="title">
    <h1>
      <span class="font-bilo-thin">ALGEMENE</span>
      <span class="font-bilo-bold display-desktop">VOORWAARDEN</span>
      <span class="font-bilo-bold display-mobile">VOORWAA...</span>
    </h1>
  </div>
</div>

<div class="content content-default">
  <p>
    Op deze pagina vindt u de algemene voorwaarden van richardjansen.net, zoals
    deze beschikbaar is gesteld door Richard Jansen. In deze algemene
    voorwaarden geven wij aan onder welk voorbehoud wij de informatie op onze
    website aan u aanbieden.
  </p>
  <h2>INTELLECTUEEL EIGENDOM</h2>
  <p>
    Het gebruik van de informatie op deze website is gratis zolang u deze
    informatie niet kopieert, verspreidt of op een andere manier gebruikt of
    misbruikt. U mag de informatie op deze website alleen hergebruiken volgens
    de regelingen van het dwingend recht.
  </p>
  <p>
    Zonder uitdrukkelijke schriftelijke toestemming van Richard Jansen is het
    niet toegestaan tekst, fotomateriaal of andere materialen op deze website
    her te gebruiken. Het intellectueel eigendom berust bij Richard Jansen.
  </p>
  <h2>INDIEN VAN TOEPASSING</h2>
  <p>
    Voor de prijzen die op onze website staan, geldt dat wij streven naar een zo
    zorgvuldig mogelijke weergave van de realiteit en de bedoelde prijzen.
    Fouten die daarbij ontstaan en herkenbaar zijn als programmeer dan wel
    typefouten, vormen nooit een aanleiding om een contract dan wel overeenkomst
    met Richard Jansen te mogen claimen of te veronderstellen.
  </p>
  <p>
    Richard Jansen streeft naar een zo actueel mogelijke website. Mocht ondanks
    deze inspanningen de informatie van of de inhoud op richardjansen.net
    onvolledig en of onjuist zijn, dan kunnen wij daarvoor geen
    aansprakelijkheid aanvaarden.
  </p>
  <p>
    De informatie en/of producten op deze website worden aangeboden zonder enige
    vorm van garantie en of aanspraak op juistheid. Wij behouden ons het recht
    voor om deze materialen te wijzigen, te verwijderen of opnieuw te plaatsen
    zonder enige voorafgaande mededeling. Richard Jansen aanvaardt geen
    aansprakelijkheid voor enige informatie die op websites staat waarnaar wij
    via hyperlinks verwijzen.
  </p>
  <h2>WIJZIGINGEN</h2>
  <p>
    Mochten deze algemene voorwaarden wijzigen, dan vindt u de meest recente
    versie van de disclaimer van richardjansen.net op deze pagina.
  </p>
</div>
