<div class="banner banner-default">
  <div class="title">
    <h1>
      <span class="font-bilo-thin">PRIVACY</span>
      <span class="font-bilo-bold">STATEMENT</span>
    </h1>
  </div>
</div>

<div class="content content-default">
  <p>
    R. Jansen respecteert de privacy van de bezoekers van de Website, in het
    bijzonder de rechten van bezoekers met betrekking tot de geautomatiseerde
    verwerking van persoonsgegevens. Vanwege volledige transparantie met onze
    klanten hebben wij daarom een beleid geformuleerd en ge&iuml;mplementeerd
    met betrekking tot deze verwerkingen zelf, het doel ervan alsook de
    mogelijkheden voor betrokkenen om hun de rechten zo goed mogelijk te kunnen
    uitoefenen. Voor alle aanvullende informatie over de bescherming van
    persoonsgegevens kunt u terecht op de website van de
    <a href="https://autoriteitpersoonsgegevens.nl/nl" target="_blank"
      >Autoriteit Persoonsgegevens</a
    >. De huidige versie van de privacyverklaring is de enige versie die van
    toepassing is.
  </p>
  <p>
    Totdat u op de website het gebruik van cookies en andere tracking systems
    accepteert, plaatsen wij geen niet-geanonimiseerde analytische cookies en/of
    tracking cookies op uw computer, mobiele telefoon of tablet. Met het
    voortzetten van het bezoek van de Website accepteert u de volgende
    gebruikersvoorwaarden.
  </p>
  <h2>Artikel 1 - Wettelijke bepalingen</h2>
  <ol>
    <li>Website (hierna ook 'de website'): richardjansen.net</li>
    <li>
      Verantwoordelijke voor de verwerking van persoonsgegevens (Hierna ook: 'De
      beheerder'): R. Jansen, wonende te De Boberden 4, 6039 RW Stramproy.
    </li>
  </ol>

  <h2>Artikel 2 - Toegang tot de website</h2>
  <p>
    De toegang tot de website en het gebruik is strikt persoonlijk. U zult deze
    website alsook de gegevens en informatie die daarop verstrekt worden niet
    gebruiken voor commerci&euml;le, politieke of publicitaire doeleinden, dan
    wel voor enige commerci&euml;le aanbiedingen en in het bijzonder niet
    gebruiken voor ongevraagde elektronische aanbiedingen.
  </p>
  <h2>Artikel 3 - De content van de website</h2>
  <p>
    Alle merken, afbeeldingen, teksten, commentaren, illustraties,
    (animatie)plaatjes, videobeelden, geluiden, alsook alle technische
    applicaties die gebruikt kunnen worden om de website te laten functioneren
    en meer in het algemeen alle onderdelen die op deze site zijn gebruikt, zijn
    beschermd bij wet door intellectuele eigendomsrechten. Iedere reproductie,
    herhaling, gebruik of aanpassing, op welke wijze dan ook, van het geheel of
    slechts een onderdeel ervan, met inbegrip van de technische applicaties,
    zonder voorafgaande schriftelijke toestemming van de verantwoordelijke, is
    ten strengste verboden. Indien de beheerder niet meteen actie onderneemt
    tegen enig inbreuk, kan dat niet worden opgevat als stilzwijgende
    toestemming dan wel het afzien van rechtsvervolging.
  </p>
  <h2>Artikel 4 - Het beheer van de website</h2>
  <p>Voor het goede beheer van de website kan de beheerder op ieder moment:</p>
  <ul>
    <li>
      de toegang aan een bepaalde categorie bezoekers schorsen, onderbreken of
      beperken tot de gehele of een gedeelte van de website;
    </li>
    <li>
      alle informatie verwijderen die het functioneren van de website kan
      verstoren of in strijd is met nationale of internationale wetgeving of in
      strijd is met internet-etiquette;
    </li>
    <li>
      de website tijdelijk niet beschikbaar hebben teneinde updates uit te
      kunnen voeren.
    </li>
  </ul>
  <h2>Artikel 5 - Verantwoordelijkheden</h2>
  <p>
    De beheerder is in geen geval verantwoordelijk voor falen, storingen,
    moeilijkheden of onderbrekingen van het functioneren van de website,
    waardoor de website of een van zijn functionaliteiten niet toegankelijk is.
    De wijze waarop u verbinding zoekt met de website is uw eigen
    verantwoordelijkheid. U dient zelf alle geschikte maatregelen te treffen om
    uw apparatuur en uw gegevens te beschermen tegen onder andere virusaanvallen
    op het internet. U bent bovendien zelf verantwoordelijk voor de websites en
    de gegevens die u op internet raadpleegt. De beheerder is niet aansprakelijk
    voor juridische procedures die tegen u worden gevoerd:
  </p>
  <ul>
    <li>
      vanwege het gebruik van de website of diensten toegankelijk via internet;
    </li>
    <li>vanwege het schenden van de voorwaarden van deze privacy policy.</li>
  </ul>
  <p>
    De beheerder is niet verantwoordelijk voor enige schade die u zelf oploopt,
    dan wel derden of uw apparatuur oplopen als gevolg van uw verbinding met of
    het gebruik van de website. U zult zich onthouden van iedere actie tegen de
    beheerder als gevolg hiervan. Indien de beheerder betrokken raakt bij een
    geschil als gevolg van uw gebruik van deze website, is hij gerechtigd alle
    schade die hij dientengevolge lijdt en nog zal lijden op u te verhalen.
  </p>

  <h2>Artikel 6 - Het verzamelen van gegevens</h2>

  <p>
    Uw gegevens worden verzameld door R. Jansen. Onder persoonsgegevens worden
    verstaan: alle informatie over een ge&iuml;dentificeerde of identificeerbare
    natuurlijke persoon; als identificeerbaar wordt beschouwd een natuurlijke
    persoon die direct of indirect kan worden ge&iuml;dentificeerd, met name aan
    de hand van een identificator zoals een naam, een identificatienummer,
    locatiegegevens, een online identificator of een of meer elementen die
    kenmerkend zijn voor de fysieke, fysiologische, genetische, psychische,
    economische, culturele of sociale identiteit. De persoonsgegevens die op de
    website worden verzameld worden hoofdzakelijk gebruikt door de beheerder
    voor het onderhouden van relaties met u en indien aan de orde voor het
    verwerken van uw bestellingen.
  </p>
  <h2>Artikel 7 - Uw rechten met betrekking tot uw gegevens</h2>

  <p>
    Op grond van de uitvoeringswet Algemene Verordening Gegevensbescherming
    (AVG) heeft eenieder recht op inzage van en rectificatie of wissing van zijn
    persoonsgegevens of beperking van de hem betreffende verwerking, alsmede het
    recht tegen de verwerking bezwaar te maken en het recht op
    gegevensoverdraagbaarheid. U kunt deze rechten uitoefenen door contact met
    ons op te nemen via
    <a target="_blank" href="mailto:richardjansen71@gmail.com"
      >richardjansen71@gmail.com</a
    >. Ieder verzoek daartoe dient te worden vergezeld van een kopie van een
    geldig identiteitsbewijs, waarop u uw handtekening heeft gezet en onder
    vermelding van het adres waarop er met u contact kan worden opgenomen.
    Binnen 1 maand na het ingediende verzoek, krijgt u antwoord op uw verzoek.
    Afhankelijk van de complexiteit van de verzoeken en het aantal van de
    verzoeken kan deze termijn indien nodig met 2 maanden worden verlengd.
  </p>
  <h2>Artikel 8 - Verwerking van persoonsgegevens</h2>
  <p>
    In geval van schending van enige wet- of regelgeving, waarvan de bezoeker
    wordt verdacht en waarvoor de autoriteiten persoonsgegevens nodig hebben die
    de beheerder heeft verzameld, worden deze aan hen verstrekt na een
    uitdrukkelijk en gemotiveerd verzoek van die autoriteiten, waarna deze
    persoonsgegevens mitsdien niet meer onder de bescherming van de bepalingen
    van deze privacyverklaring vallen. Indien bepaalde informatie noodzakelijk
    is om toegang te krijgen tot bepaalde functionaliteiten van de website, zal
    de verantwoordelijke het verplichte karakter van deze informatie aangeven op
    het moment van het vragen van de gegevens.
  </p>
  <h2>Artikel 9 - Commerci&euml;le aanbiedingen</h2>
  <p>
    U kunt commerci&euml;le aanbiedingen krijgen van de beheerder. Indien u deze
    niet (meer) wenst te ontvangen, stuurt u een mail naar het volgende adres:
    <a target="_blank" href="mailto:richardjansen71@gmail.com"
      >richardjansen71@gmail.com</a
    >. Indien u tijdens het bezoek van de website enige persoonsgegevens
    tegenkomt, dient u zich te onthouden van het verzamelen ervan of van enig
    ander ongeoorloofd gebruik alsook van iedere daad die een inbreuk op de
    persoonlijke levenssfeer van die perso(o)n(en) oplevert. De beheerder is in
    geen geval verantwoordelijk in bovengenoemde situaties.
  </p>

  <h2>Artikel 10 - Bewaartermijn gegevens</h2>
  <p>
    De door de beheerder van website verzamelde gegevens worden gebruikt en
    bewaard voor de duur zoals deze bij wet is bepaald.
  </p>

  <h2>Artikel 11 - Beeldmateriaal en aangeboden producten</h2>
  <p>
    Aan het beeldmateriaal dat behoort bij de aangeboden producten op de website
    kunnen geen rechten worden ontleend.
  </p>
  <h2>Artikel 12 - Toepasselijk recht</h2>
  <p>
    Op deze voorwaarden is Nederlands Recht van toepassing. De rechtbank van de
    woonplaats van de beheerder is exclusief bevoegd bij eventuele geschillen
    omtrent deze voorwaarden, behoudens wanneer hierop een wettelijke
    uitzondering van toepassing is.
  </p>
  <h2>Artikel 13 - Contact</h2>
  <p>
    Voor vragen, productinformatie of informatie over de website zelf, kunt u
    zich richten tot: Richard Jansen,
    <a target="_blank" href="mailto:richardjansen71@gmail.com"
      >richardjansen71@gmail.com</a
    >.
  </p>

  <p>Deze privacyverklaring is van toepassing sinds 1 Januari 2023</p>
</div>
