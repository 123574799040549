<div class="banner">
  <div class="title">
    <!-- <h1>
      <span class="font-bilo-bold">NO</span
      ><span class="font-bilo-light">GRAY</span
      ><span class="font-bilo-thin">TO</span
      ><span class="font-bilo-thin">DAY</span>
    </h1> -->
  </div>
</div>
<div class="teaser">
  <div class="content">
    <img class="pic pic--left" src="/assets/home/carre-boerderij.jpg" />
    <!-- <div class="date">10-11 Juni 2023</div> -->
    <h2>VASTE EXPOSITIE IN KASTEEL PUTH</h2>
    <p>
      Zo'n 500 bezoekers heb ik mogen ontvangen gedurende tweedaagse pop-up
      expositie “No Gray Today” afgelopen juni in de carr&eacute;boerderij van
      Kasteel Puth te Voerendaal. Een mooi resultaat. Het beste resultaat is dat
      ik deze locatie van de buitencategorie als vaste expositie ruimte blijvend
      mag inrichten. Wat een voorrecht. Een dertig tal schilderijen zal hier
      vanaf nu te bezichtigen zijn. Naast een aantal old timers zal ik hier mijn
      meest recente werk tonen. Ook mijn toekomstige werk zal als eerste hier te
      bezichtigen zijn. De locatie is alleen te bezichtigen op afspraak.
      Interesse? Neem dan contact op via
      <a href="mailto:richardjansen71@gmail.com" target="_blank"
        >richardjansen71@gmail.com</a
      >.
    </p>
  </div>
</div>
<div class="paintings">
  <div class="content">
    <h2>RECENTE SCHILDERIJEN</h2>
    <ul>
      <li>
        <div class="thumb">
          <img src="/assets/home/recent-01.jpg" />
        </div>
        <div class="title">La Forêt</div>
        <div class="size">120 x 150cm</div>
        <div class="method">Acryl op canvas</div>
        <div class="price">
          <a
            target="_blank"
            href="mailto:richardjansen71@gmail.com?subject=richardjansen.net price request: THROUGH ICE AND SNOW"
            >Prijs op aanvraag</a
          >
        </div>
      </li>
      <li class="sold">
        <div class="thumb">
          <img src="/assets/home/recent-02.jpg" />
        </div>
        <div class="title">En el Espacio Exterior</div>
        <div class="size">120 x 150cm</div>
        <div class="method">Acryl op canvas</div>
        <div class="price">Verkocht</div>
      </li>
      <li class="sold">
        <div class="thumb">
          <img src="/assets/home/recent-03.jpg" />
        </div>
        <div class="title">Nombre d'Or</div>
        <div class="size">120 x 150cm</div>
        <div class="method">Acryl op canvas</div>
        <div class="price">Verkocht</div>
      </li>
      <li>
        <div class="thumb">
          <img src="/assets/home/recent-04.jpg" />
        </div>
        <div class="title">Medio Borracho (es una Perdida de Dinero)</div>
        <div class="size">120 x 150cm</div>
        <div class="method">Acryl op canvas</div>
        <div class="price">
          <a
            target="_blank"
            href="mailto:richardjansen71@gmail.com?subject=richardjansen.net price request: SIOUX"
            >Prijs op aanvraag</a
          >
        </div>
      </li>
    </ul>
    <hr />
    <div class="goto" routerLink="/gallery" routerLinkActive="active">
      Naar schilderijen
    </div>
  </div>
</div>
<div class="content">
  <div class="blog">
    <img src="/assets/home/buitenatelier.jpg" />
    <h2>MIJN ULTIEME ATELIER</h2>
    <p>
      Ter voorbereiding van de Kunstroute in Stramproy heb ik vorig jaar zomer
      gedurende twee weekenden geschilderd in onze tuin. Het weer was
      fantastisch. Een beter atelier kan ik mij niet wensen. Een geslaagd
      experiement, want in totaal zou ik 10 schilderijen maken. Met de lente in
      aantocht hoop ik binnenkort weer buiten te kunnen schilderen. Dit keer ter
      voorbereiding van mijn expositie in Kasteel Puth te Voerendaal.
    </p>
  </div>
</div>
