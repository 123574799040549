import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import  impressions  from  './../../assets/impressions.json';
import { IImpression } from './../impression';

@Component({
  selector: 'app-gallery',
  templateUrl: './impressions.component.html',
  styleUrls: ['./impressions.component.scss']
})
export class ImpressionsComponent implements OnInit {

  // make DOM element with attribute #carrousel accessible
  @ViewChild('carrousel', { read: ElementRef }) public carrousel: ElementRef<any>;
  @ViewChild('bullets', { read: ElementRef }) public bullets: ElementRef<any>;
  @ViewChild('transitions', { read: ElementRef }) public transitions: ElementRef<any>;

  curPage: number;
  impressions: IImpression[] = [];
  browserWidth: number = window.innerWidth;
  prevImageWidth: number;
  scrollBehavior: any;

  constructor() { 
    this.curPage = 0;
    ;
  }

  ngOnInit() {
    this.impressions = impressions;
    this.setScrollBehavior();
  }

 setScrollBehavior(){
    // set scroll behavior to instant in case of mobile
    let ua = navigator.userAgent;
    if(/Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini|CriOS/i.test(ua)) {
       this.scrollBehavior = "instant"
      }
    else {
      this.scrollBehavior = "smooth"
    }
};
  

  ngAfterViewInit(): void {
    // time out is needed to get the actual image properties
    setTimeout(()=>{ 
       this.initCarrousel(); 
    }, 200);
    // set transitions behavior to either smooth or instant
    this.transitions.nativeElement.classList.add(this.scrollBehavior);
    
  }

  initCarrousel() {
    // determine initial image id
    let id = 'impression-' + this.curPage;
    var element: any = document.getElementById(id);
    var elementProps = element.getBoundingClientRect();
    var initImageWidth = elementProps.width;
    console.log("imageWIDTH ",initImageWidth);
    var initPadding =  (this.browserWidth/2) - (initImageWidth/2);
    let el = this.carrousel.nativeElement;
    // if(this.browserWidth > 400) {
      el.setAttribute('style', 'padding-left:'+initPadding+'px');
    // }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    location.reload();
  }

  navigate(num:number) {
    let id = 'impression-' + num;
    this.curPage = num;
    let el: any = document.getElementById(id);
    let elProperties = el.getBoundingClientRect();
    let imageWidth = elProperties.width;
    var centerPosition =  (this.browserWidth/2) - (imageWidth/2);
    let xPos = elProperties.x;
    this.carrousel.nativeElement.scrollTo({ left: (this.carrousel.nativeElement.scrollLeft + xPos - centerPosition), behavior: this.scrollBehavior });
    
  }

}
